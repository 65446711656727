
html, body { 
    height: 100%; 
}

body { 
    margin: 0; 
    font-family: Roboto, "Helvetica Neue", sans-serif; 
}

.login-wrapper {
    height: 100%;
}

.box {
    position: relative;
    top: 0;
    opacity: 1;
    float: left;
    padding: 60px 50px 40px 50px;
    width: 100%;
    background: #fff;
    border-radius: 10px;
    transform: scale(1);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    z-index: 5;
    max-width: 330px;
}

.box.back {
    transform: scale(.95);
    -webkit-transform: scale(.95);
    -ms-transform: scale(.95);
    top: -20px;
    opacity: .8;
    z-index: -1;
}

.box:before {
    width: 100%;
    height: 30px;
    border-radius: 10px;
    position: absolute;
    top: -10px;
    background: rgba(255, 255, 255, .6);
    left: 0;
    transform: scale(.95);
    -webkit-transform: scale(.95);
    -ms-transform: scale(.95);
    z-index: -1;
}

.login-wrapper .example-form {
    min-width: 100%;
    max-width: 300px;
    width: 100%;
}

.login-wrapper .example-full-width,
.login-wrapper .btn-block {
    width: 100%;
}

.login-wrapper mat-card-header {
    text-align: center;
    width: 100%;
    display: block;
    font-weight: 700;
}

.login-wrapper mat-card-header mat-card-title {
    font-size: 30px;
    margin: 0;
}

.login-wrapper .mat-card {
    padding: 40px 70px 50px;
}

.login-wrapper .mat-stroked-button {
    border: 1px solid currentColor;
    line-height: 54px;
    background: #FFF7FA;
}

.login-wrapper .mat-form-field-appearance-legacy .mat-form-field-infix {
    padding: 0.8375em 0;
}

.card {
    min-width: 98%;
}
  
.example-container {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    min-width: 100%;
}

.mb {
    margin: 20px;
}

.logo-container {
    display: flex;
    justify-content: center;
}

.logo-menu {
    height: 50px;
    width: 50px;
}

.material-icons.color-green { color: #00FF00; }

.toolbar-spacer {
    flex: 1 1 auto;
}